<template>
  <div class="pa-4">
    <div
      v-t="'productDetails.numberOfUsers'"
      class="user-count-slider-title mb-7"
    />
    <div class="d-none d-sm-flex">
      <div class="mr-2">
        {{ minNumberOfUsers }}
      </div>
      <v-slider
        v-model="maxUserCount"
        :min="minNumberOfUsers"
        :max="maxNumberOfUsers"
        :step="stepNumberOfUsers"
        thumb-label="always"
      >
        <template #thumb-label>
          {{ maxUserCount }}
        </template>
      </v-slider>
      <div class="ml-2">
        {{ maxNumberOfUsers }}
      </div>
    </div>
    <div class="d-block d-sm-none">
      <v-select
        v-model="maxUserCount"
        :items="numberOfUsers"
        hide-details
      />
    </div>
  </div>
</template>

<script>
import { TYPE_CLOUD } from '@/constants/app'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    initialValue: {
      type: Number,
      default: 5
    }
  },
  data () {
    return {
      maxUserCount: this.initialValue,
      minNumberOfUsers: this.type === TYPE_CLOUD ? 5 : 10,
      maxNumberOfUsers: 100,
      stepNumberOfUsers: 5,
      numberOfUsers: this.type === TYPE_CLOUD
        ? Array(20).fill().map((_, i) => ((i + 1) * 5))
        : Array(19).fill().map((_, i) => ((i + 2) * 5))
    }
  },
  watch: {
    maxUserCount (val, oldVal) {
      if (val !== oldVal) {
        this.$emit('change', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-count-slider-title {
  text-align: center;
  font-size: 16px;
}

::v-deep .v-slider__thumb-label {
  background: none !important;
  color: $color--brand !important;
  font-weight: 500;
  font-size: 16px;
  transform: translateY(150%) translateY(-12px) translateX(-50%) rotate(45deg) !important;
}
</style>
